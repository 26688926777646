import https from '@/router/https'
//粉丝订单
export function getOrderList(param) {
    return https.fetchGet('/mallapp/fans/pageFansOder', param);
}
//订单评价
export function commentOrder(param) {
    return https.fetchPost('/mallapp/goodsappraises/save', param);
}
//取消订单
export function cancelOrder(id) {
    return https.fetchPost('/mallapp/orderinfo/cancel/', id);
}
//获取我的订单
export function getMyOrder(param)
{
    return https.fetchGet('/mallapp/movieorder/page',param);
}