<template>
  <div style="position: fixed;
    width: 100%">
    <!--导航栏--->
          <div>
        <van-nav-bar
  title="台北原唱量贩KTV（南七店）"
  left-arrow
  fixed="true"
   @click-left="$router.back(-1)"
/>
          </div>
<!--评价-->
<div >
  <div class="content"> <span class="word3">我的评价</span></div>
  <div class="content1">
    <van-rate
     :value="value"
  size="25"
  color="#ee0a24"
  void-color="#eee"
  void-icon="star"
      @change="onChange"

/>
  </div>
   <div class="content2">
          <van-cell-group>
  <van-field
   v-model="valuetxt"
    type="textarea"
    placeholder="说说商家的亮点和不足吧"
    autosize="maxHeight:100px"
    border="true"

  />
</van-cell-group>
            </div>


</div>
 <!-- 底部 -->
    <div>
      <van-goods-action>
        <van-goods-action-button type="warning" text="发布"  @click="CommentOrder"/>
      </van-goods-action>
    </div>
  </div>
</template>
<script>
import {commentOrder} from '@/api/order/order'
import { Toast } from 'vant';
export default {
   data() {
    return {
      value:0,
      valuetxt:''
    };
  },
  created() {},
  mounted() {},
  methods: { 
      onChange(event) {
      this.value=event
    },
    CommentOrder()
    {
         //let params={'orderId':this.$route.query.Id,'goodsScore':this.value,'content':this.valuetxt} '1305804330915368962'
         //alert(this.$route.query.Id)
           let params={'orderId':this.$route.query.Id,'goodsScore':this.value,'content':this.valuetxt}
           commentOrder(params).then((res)=>
           {
             if(res.data.code===0)
             {
                 this.$router.back(-1)
             }else
             {
               Toast('评价失败，请重新评价')
             }
           })
    }
    },
  watch: {},
  computed: {},
}
</script>

<style lang="less">
.content{
  position: flex;
  margin-top:80px;
}
.content1{
  position: flex;
  margin-top:20px;
  text-align: center;
  align-self: center;
}
.content2{
  position: flex;
  margin-top:30px;
  text-align: center;
  align-self: center;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border:1px solid #000
}
.word3 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: center;
  align-self: center;
}
.box2 {
  height: 100px;
  border: 1px solid rgba(224, 224, 224, 1);
  align-self: center;
  margin-top: 20px;
  width: 343px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0 0 10px;
}

.word4 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;
  text-align: left;
}
</style>
